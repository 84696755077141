import { FC } from "react";

export const IconGrocery: FC<{ color: "white" | "uyellow" }> = ({ color }) => {
  const _color = color === "white" ? "white" : "#FFCE16";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="43"
      height="43"
      viewBox="0 0 43 43"
      fill="none"
    >
      <g clipPath="url(#clip0_675_17145)">
        <path
          d="M1.79167 1.79199C0.802157 1.79199 0 2.59415 0 3.58366C0 4.57317 0.802157 5.37533 1.79167 5.37533H4.97957C5.24043 5.37533 5.49468 5.45741 5.7063 5.60994C5.91791 5.76247 6.07617 5.97773 6.15865 6.2252L12.0658 23.9499C12.3281 24.7368 12.3614 25.5842 12.1602 26.3889L11.6738 28.3381C10.9601 31.1929 13.1823 34.042 16.125 34.042H37.625C38.6145 34.042 39.4167 33.2398 39.4167 32.2503C39.4167 31.2608 38.6145 30.4587 37.625 30.4587H16.125C15.4274 30.4587 14.9795 29.8862 15.1487 29.2094L15.5155 27.7443C15.5776 27.4961 15.721 27.2757 15.9227 27.1183C16.1244 26.9608 16.373 26.8753 16.6289 26.8753H35.8333C36.6048 26.8757 37.2899 26.3823 37.534 25.6506L42.3106 11.3172C42.6976 10.1566 41.8334 8.9581 40.61 8.95866H11.7438C11.483 8.95866 11.2287 8.87658 11.0171 8.72405C10.8055 8.57152 10.6472 8.35627 10.5647 8.1088L8.86735 3.01676C8.62321 2.28498 7.93811 1.79159 7.16667 1.79199H1.79167ZM14.3333 35.8337C12.3543 35.8337 10.75 37.438 10.75 39.417C10.75 41.396 12.3543 43.0003 14.3333 43.0003C16.3124 43.0003 17.9167 41.396 17.9167 39.417C17.9167 37.438 16.3124 35.8337 14.3333 35.8337ZM35.8333 35.8337C33.8543 35.8337 32.25 37.438 32.25 39.417C32.25 41.396 33.8543 43.0003 35.8333 43.0003C37.8124 43.0003 39.4167 41.396 39.4167 39.417C39.4167 37.438 37.8124 35.8337 35.8333 35.8337Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_675_17145">
          <rect width="43" height="43" fill={_color} />
        </clipPath>
      </defs>
    </svg>
  );
};
