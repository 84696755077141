import { useEffect, useMemo, useState } from "react";
import { MarketItem } from "../../../logic/types";
import { InternalDataItem } from "../../DataField";
import { WayToPayRadioOptions } from "../modal-bid/ModalBid";
import { Option } from "../shared/selection-dropdown/SelectionDropdown";
import { generalLiterals } from "literals";
import { calculateLiquidationPrice } from "logic/helpers/math";
import { WalletDropdownElement } from "../shared/selection-dropdown";
import { usePromiseProperty } from "logic/hooks";

const { liquidationPriceLabel, minPaymentLabel } =
  generalLiterals.modals.buyNow;

export function useModalBuyoutDataItems(
  radioSelected: WayToPayRadioOptions,
  marketItem: MarketItem,
  debt: bigint,
  minInitialPayment: bigint
) {
  const valuation = usePromiseProperty("valuation", marketItem.nft);
  const liquidationThreshold = usePromiseProperty(
    "liquidationThreshold",
    marketItem.nft
  );

  return useMemo<InternalDataItem[]>(() => {
    const collection: InternalDataItem = {
      label: "Collection",
      value: marketItem.nft.name,
      type: "string",
    };

    const tokenId: InternalDataItem = {
      label: "Token ID",
      value: `#${marketItem.nft.tokenId}`,
      type: "string",
    };

    const valuationItem: InternalDataItem = {
      label: "Valuation",
      value: valuation,
      type: "currency",
      currency: marketItem.currency,
    };

    const _minInitialPayment: InternalDataItem = {
      label: minPaymentLabel,
      value: minInitialPayment,
      type: "currency",
      currency: marketItem.currency,
    };

    const liquidationPrice: InternalDataItem = {
      label: liquidationPriceLabel,
      value:
        liquidationThreshold !== undefined
          ? calculateLiquidationPrice(debt, liquidationThreshold)
          : undefined,
      type: "currency",
      currency: marketItem.currency,
    };

    return radioSelected === WayToPayRadioOptions.FULL_PRICE
      ? [collection, tokenId, valuationItem]
      : [
          collection,
          valuationItem,
          _minInitialPayment,
          tokenId,
          liquidationPrice,
        ];
  }, [
    radioSelected,
    marketItem,
    minInitialPayment,
    debt,
    valuation,
    liquidationThreshold,
  ]);
}

export function useWalletToDepositSelection(
  wayToPay: WayToPayRadioOptions,
  hasSmartAddress: boolean
) {
  const [options, setOptions] = useState<Option[]>([]);
  const [dropdownShown, setDropdownShown] = useState<boolean>(false);
  const [claimOnUWallet, setClaimOnUWallet] = useState<boolean>();

  const handleOptionClick = (value: "u-wallet" | "external-wallet") => {
    setOptionSelected(value);
  };

  const [optionSelectedValue, setOptionSelected] = useState<
    "u-wallet" | "external-wallet"
  >();

  const externalWalletOption: Option = {
    selected: false,
    Content: (
      <WalletDropdownElement
        label="Connected Wallet"
        imageSrc="/assets/images/wallet-logo.png"
      />
    ),
    value: "external-wallet",
    handleClick: () => handleOptionClick("external-wallet"),
  };

  const uWalletOption: Option = {
    selected: false,
    Content: (
      <WalletDropdownElement
        label="Unlockd Account"
        imageSrc="/assets/images/wallet-logo.png"
      />
    ),
    value: "unlockd-wallet",
    handleClick: () => handleOptionClick("u-wallet"),
  };

  useEffect(() => {
    if (wayToPay === WayToPayRadioOptions.TAKE_LOAN) {
      setDropdownShown(false);
      setClaimOnUWallet(true);
    } else if (!hasSmartAddress) {
      setDropdownShown(false);
      setClaimOnUWallet(false);
    } else {
      setDropdownShown(true);
      setClaimOnUWallet(optionSelectedValue === "u-wallet");

      setOptions([
        {
          ...externalWalletOption,
          selected: optionSelectedValue === "external-wallet",
        },
        {
          ...uWalletOption,
          selected:
            optionSelectedValue === undefined ||
            optionSelectedValue === "u-wallet",
        },
      ]);

      if (optionSelectedValue === undefined) {
        setOptionSelected("u-wallet");
      }
    }
  }, [wayToPay, hasSmartAddress, optionSelectedValue]);

  return { options, dropdownShown, claimOnUWallet };
}
