import { FC, useMemo } from "react";
import { BasicProps } from "../../types/BasicProps";
import classNames from "classnames";

type Props = BasicProps & {
  value: string;
  label: string;
  isSelected: boolean;
  type: "radio" | "checkbox";
  onSelected: (value: string) => void;
  infoDescription?: string;
  circleHidden?: boolean;
  Icon: FC<{ color: "white" | "uyellow" }>;
};

const SquareInput: FC<Props> = (props) => {
  const {
    className,
    isSelected,
    onSelected,
    value,
    label,
    type,
    Icon,
    circleHidden = false,
    infoDescription,
  } = props;

  const iconColor = useMemo<"white" | "uyellow">(
    () => (isSelected ? "uyellow" : "white"),
    [isSelected]
  );

  return (
    <>
      <label
        className={classNames(
          "relative py-[12px] border rounded-lg flex flex-col justify-center items-center gap-2 transition-colors",
          className,
          isSelected
            ? "border-uyellow shadow-[0px_0px_4px_0px_#FFCE16]"
            : "border-white"
        )}
        htmlFor={value}
      >
        <input
          className={classNames(
            circleHidden && "hidden",
            "absolute top-2 right-3 z-50 w-3 h-3 border-2 border-white outline outline-black rounded-full checked:bg-uyellow bg-white"
          )}
          type={type}
          checked={isSelected}
          onChange={() => onSelected(value)}
          id={value}
          name={value}
          value={value}
        />
        <Icon color={iconColor} />
        <div className="flex gap-0.5">
          <span
            className={classNames(
              "text-xs font-medium",
              isSelected && "text-uyellow"
            )}
          >
            {label}
          </span>
        </div>
      </label>
    </>
  );
};

export default SquareInput;
