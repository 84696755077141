import classNames from "classnames";
import {
  type ChangeEventHandler,
  type FC,
  type KeyboardEventHandler,
} from "react";
import { useCounter } from "react-use";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { BasicProps } from "../../types/BasicProps";

type Props = BasicProps & {
  nameInput: string;
  onChange?: (arg: number) => void;
  max: number;
};

export const Selector: FC<Props> = ({
  className,
  nameInput,
  onChange,
  max,
}) => {
  const [value, { inc, dec, set }] = useCounter(0, Infinity, 0);

  const handleChange: ChangeEventHandler = (event) => {
    const { value } = event.target as HTMLInputElement;
    const number = Number(value);
    if (number >= max) {
      set(max);

      onChange?.(max);
      return;
    }
    if (number === 0 || number) {
      set(number);

      onChange?.(number);
    }
  };

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (event.key === "ArrowUp" && value < max) {
      onChange?.(value + 1);

      return inc(1);
    }
    if (event.key === "ArrowDown") {
      if (value > 0) onChange?.(value - 1);

      return dec(1);
    }
  };

  const onUpButton = () => {
    onChange?.(value + 1);

    inc(1);
  };

  const onDownButton = () => {
    if (value <= 1) return;

    onChange?.(value - 1);

    dec(1);
  };

  const onMaxButton = () => {
    set(max);

    onChange?.(max);
  };

  return (
    <>
      <div className={classNames(``, [className])}>
        <label
          className="border-2 rounded-xl px-2.5 pt-2 pb-2 block
           focus-within:border-primary"
        >
          <div className="flex justify-between mb-2">
            <span className="text-2xs xs:text-xs block max-3xs:hidden">
              Amount
            </span>
            <span className="text-2xs xs:text-xs block">
              {max + " " + "NFTs available to mint"}
            </span>
          </div>
          <div className="flex items-center">
            <input
              onKeyDown={handleKeyDown}
              type="text"
              name={nameInput}
              className="bg-transparent w-8 text-white !outline-none text-2xl xs:text-3xl xs:ml-2 ml-1.5"
              onChange={handleChange}
              value={value}
              autoComplete="off"
            />
            <div className="px-2">
              <button
                type="button"
                onClick={onUpButton}
                disabled={value >= max}
                className="w-9 xs:w-10 h-5 xs:h-6 disabled:!text-slate-400 hover:bg-primary rounded-lg transition-colors
              disabled:bg-transparent duration-300 focus-visible:bg-primary active:contrast-200
              flex items-center justify-center"
              >
                <FaChevronUp className="w-full h-full" />
              </button>
              <button
                type="button"
                onClick={onDownButton}
                disabled={value <= 1}
                className="w-9 xs:w-10 h-5 xs:h-6 disabled:text-slate-400 hover:bg-primary rounded-lg transition-colors 
              disabled:bg-transparent duration-300 focus-visible:bg-primary active:contrast-200
              flex items-center justify-center"
              >
                <FaChevronDown className="w-full h-full" />
              </button>
            </div>
            <button
              type="button"
              disabled={value >= max}
              onClick={onMaxButton}
              className="bg-white text-primary rounded-lg ml-auto text-xs xs:text-sm px-3
            font-bold h-8 hover:bg-primary hover:text-white active:contrast-200 transition-colors duration-300
            focus-visible:bg-primary focus-visible:text-white
            disabled:bg-slate-200 disabled:text-slate-400"
            >
              max
            </button>
          </div>
        </label>
      </div>
    </>
  );
};
