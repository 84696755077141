import { useMemo } from "react";
import { InternalDataItem } from "../../DataField";
import { generalLiterals } from "literals";
import { Loan } from "logic/types/loan/Loan";
import { INft } from "logic/types/nft/INft";

const { sellPriceLabel, collectionLabel, tokenIdLabel, debtLabel } =
  generalLiterals.modals.instantSell;

export function useModalInstantSellItems(
  nft: INft,
  loan: Loan,
  sellPrice?: bigint | null
) {
  return useMemo<InternalDataItem[]>(() => {
    const collection: InternalDataItem = {
      label: collectionLabel,
      value: nft.name,
      type: "string",
    };

    const tokenId: InternalDataItem = {
      label: tokenIdLabel,
      value: `#${nft.tokenId}`,
      type: "string",
    };

    const instantSell: InternalDataItem = {
      label: sellPriceLabel,
      value:
        sellPrice === null
          ? "No offers"
          : sellPrice === undefined
          ? "-.---"
          : sellPrice,
      type: !sellPrice ? "string" : "currency",
    };

    if (instantSell.type === "currency") {
      instantSell.currency = nft.currency;
    }

    const totalDebt: InternalDataItem = {
      label: debtLabel,
      value: loan.currentBorrowAmount,
      type: "currency",
      currency: loan.currency,
    };

    return [collection, tokenId, instantSell, totalDebt];
  }, [nft, sellPrice, loan]);
}
