import { Address } from "viem";
import UnlockdService from "../data/UnlockdService";
import { SupportedChainIds, externalWalletModule } from "../clients/verisModule";

export default async function loginOnUnlockdApi(
  address: Address,
  chainId: SupportedChainIds
): Promise<string> {
  const unlockdService = UnlockdService.get(chainId);

  const message = await unlockdService.getAuthMessage(
    address.toLowerCase() as Address
  );

  const signature = await signMessage(message);

  return unlockdService.sendSignature(address, signature);
}

function signMessage(message: string): Promise<string> {
  return externalWalletModule.client!.signMessage({
    account: externalWalletModule.client!.account!.address,
    message,
  });
}
