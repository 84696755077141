import { FC } from "react";
import { BasicProps } from "../../../types/BasicProps";

type Props = BasicProps & {
  widthAndHeight: number;
};

export const ArrowDown: FC<Props> = ({ widthAndHeight }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={widthAndHeight}
      height={widthAndHeight}
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill="#4630E0" />
      <path
        d="M4.67701 6.47728C4.59211 6.47728 4.50723 6.44569 4.44251 6.38264L2.40577 4.3976C2.2762 4.27132 2.2762 4.06659 2.40577 3.94037C2.53528 3.81415 2.7453 3.81415 2.87487 3.94037L4.67701 5.69685L6.47915 3.94043C6.60871 3.81421 6.81872 3.81421 6.94822 3.94043C7.07784 4.06665 7.07784 4.27138 6.94822 4.39766L4.91151 6.3827C4.84675 6.44576 4.76187 6.47728 4.67701 6.47728Z"
        fill="white"
      />
    </svg>
  );
};
