import { useEffect, useState } from "react";
import { BasicProps } from "./types/BasicProps";
import displayHealthFactor from "../logic/helpers/displayHealthFactor";
import { healthFactorDescription } from "../literals";
import { HealthFactorCircle } from "./HealthFactorCircle";
import classNames from "classnames";
import { Info } from "./Info";
import TokenIcon from "./icons/TokenIcon";
import { ICurrency } from "logic/types/currency/ICurrency";

type THealthFactor = "health-factor";
type TCurrency = "currency";
type TPercentage = "percentage";
type TString = "string";

export type DataItem = {
  label?: string;
  value?: bigint | string | Promise<bigint>;
  type: THealthFactor | TCurrency | TPercentage | TString;
  currency?: ICurrency;
};

export type InternalDataItem = {
  label?: string;
  type: THealthFactor | TCurrency | TPercentage | TString;
  currency?: ICurrency;
};

type TextSize = "text-xs" | "text-sm" | "text-base" | "text-lg" | "text-xl";

type GetDataItemType<T extends InternalDataItem> = T & {
  value?: T["type"] extends TString ? string : bigint | Promise<bigint>;
};

const DataField = <T extends InternalDataItem>(
  props: BasicProps & {
    item: GetDataItemType<T>;
    labelStyles?: string;
    valueStyles?: string;
    valueTextSize?: TextSize;
    hideHfInfo?: boolean;
  }
) => {
  const {
    item: { type, value, label, currency },
    labelStyles = "text-xs font-bold",
    valueStyles = "justify-center font-normal",
    valueTextSize = "text-base",
    className,
    hideHfInfo = false,
  } = props;
  const [displayValue, setDisplayValue] = useState<string>();
  const [awaitedValue, setAwaitedValue] = useState<string | bigint>();

  useEffect(() => {
    if (value instanceof Promise) {
      handleWaitValue(value);
    } else {
      setAwaitedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (awaitedValue !== undefined) {
      const displayValue =
        awaitedValue === undefined
          ? "-.---"
          : isHealthFactor
          ? displayHealthFactor(awaitedValue as bigint)
          : type === "percentage"
          ? `${awaitedValue.toString()}%`
          : type === "string" && typeof awaitedValue === "string"
          ? awaitedValue
          : currency?.formatAmount(awaitedValue as bigint);

      setDisplayValue(displayValue);
    }
  }, [awaitedValue]);

  const handleWaitValue = async (value: Promise<bigint>) => {
    const awaitedValue = await value;

    setAwaitedValue(awaitedValue);
  };

  const isHealthFactor = type === "health-factor";

  const iconHeight = getHeightFromTextSize(valueTextSize);

  return (
    <div className={classNames("flex flex-col", className)}>
      {label && (
        <div className="flex justify-center items-center gap-1">
          <span className={classNames(labelStyles, "w-full")}>{label}</span>
          {isHealthFactor && !hideHfInfo && (
            <Info>
              <Info.Description>{healthFactorDescription}</Info.Description>
            </Info>
          )}{" "}
        </div>
      )}
      <div className={classNames(valueStyles, "flex items-center gap-1.5")}>
        <span className={valueTextSize}>{displayValue}</span>
        {isHealthFactor && awaitedValue !== undefined && (
          <HealthFactorCircle healthFactor={awaitedValue as bigint} />
        )}
        {currency && <TokenIcon currency={currency} className={iconHeight} />}
      </div>
    </div>
  );
};

export default DataField;

function getHeightFromTextSize(textSize: TextSize): string {
  switch (textSize) {
    case "text-xs":
      return "h-3 w-3";
    case "text-sm":
      return "h-4 w-3";
    case "text-base":
      return "h-[14px] w-[14px]";
    default:
      return "h-6 w-6";
  }
}
