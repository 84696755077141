import { Abi, Address } from "viem";
import { ERC721Contract } from "../contracts/ERC721Contract";
import { OptionsWriteMethod, Output } from "@unlockdfinance/verislabs-web3";
import {
  SupportedChainIds,
  externalWalletModule,
  verisModule,
} from "../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { app } from "config/app.config";
import nftsModule from "./NftsModule";
import { ICollection } from "./types/collection/ICollection";

class FaucetModule {
  private getErc721Contracts(chainId: SupportedChainIds) {
    const faucetCollections =
      verisModule.getNetwork(chainId).FAUCET_COLLECTIONS;

    return faucetCollections.map(({ address }) => new ERC721Contract(address));
  }

  async getAvailableNftsForAddress(
    mintAddress: Address,
    walletAddress: Address,
    chainId: SupportedChainIds
  ): Promise<{ availableNfts: number; hasAvailableNftsToMint: boolean }> {
    return this.getErc721Available(mintAddress, walletAddress, chainId);
  }

  private async getErc721Available(
    mintAddress: Address,
    walletAddress: Address,
    chainId: SupportedChainIds
  ) {
    const faucetContract = this.getErc721Contract(mintAddress, chainId);

    if (!faucetContract)
      throw new Error(`No faucet contract found for address ${mintAddress}`);

    const [balanceOfAddress, MAX_SUPPLY, totalSupply] = await Promise.all([
      faucetContract.balanceOf(walletAddress, chainId),
      faucetContract.maxSupply(chainId),
      faucetContract.totalSupply(chainId),
    ]);

    const availableNftsForUser =
      BigInt(app.MAX_NFTS_FOR_A_USER) - balanceOfAddress;

    const availableNftsInContract = MAX_SUPPLY - totalSupply;

    const availableNfts =
      availableNftsInContract > availableNftsForUser
        ? availableNftsForUser
        : availableNftsInContract;

    return {
      availableNfts: Number(availableNfts),
      hasAvailableNftsToMint: availableNfts > 0,
    };
  }

  async mintFaucet(
    collection: ICollection<Abi>,
    amount: number = 1,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    const output = await this.mintErc721(collection.address, amount, collection.chain.id, options);

    const extAddress = externalWalletModule.address;

    if (extAddress) {
      nftsModule.removeCollectionCollectionFromCache(extAddress, collection.address, collection.chain.id);
    }

    return output;
  }

  private mintErc721(
    collection: Address,
    amount: number,
    chainId: SupportedChainIds,
    options?: OptionsWriteMethod
  ) {
    const faucetContract = this.getErc721Contract(collection, chainId);

    return faucetContract.mint(externalWalletModule.address!, amount, options);
  }

  private getErc721Contract(mintAddress: Address, chainId: SupportedChainIds): ERC721Contract {
    const faucetContract = this.getErc721Contracts(chainId).find((contract) =>
      equalIgnoreCase(contract.getAddress()!, mintAddress)
    );

    if (!faucetContract)
      throw new Error(`No faucet contract found for address ${mintAddress}`);

    return faucetContract;
  }
}

export default new FaucetModule();
