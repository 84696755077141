"use client";
import { type FC, type FormEvent, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { RichText } from "../../RichText";
import { CtaPrimary } from "../..";
import { Selector } from "./Selector";
import { useUpdateEffect } from "react-use";
import { ErrorMessage, withModal } from "../shared";
import { useCustomWallet } from "../../providers";
import useAlertNotification from "../../hooks/useAlertNotification";
import {
  GasLimitError,
  LimitMaxError,
  LimitMinError,
  UnexpectedError,
  UserDeniedTransactionSignatureError,
} from "../../../errors";
import { approveInWalletLiteral, lifeCycleLiterals } from "../../../literals";
import { Abi } from "viem";
import { ModalProps } from "../../types/ModalProps";
import faucetModule from "../../../logic/FaucetModule";
import { Hash } from "@unlockdfinance/verislabs-web3";
import Chain from "logic/types/chain/Chain";
import { ICollection } from "logic/types/collection/ICollection";

type Props = ModalProps & {
  availableNfts: number;
  onNewTransaction: (tx: { hash: Hash; chain: Chain }) => void;
  collection: ICollection<Abi>;
  onMinted?: () => void;
};

export const ModalMintNfts: FC<Props> = withModal(
  ({ toggleModal, onNewTransaction, availableNfts, collection, onMinted }) => {
    const [amount, setAmount] = useState<number | null>(null);
    const [tx, setTx] = useState<{ hash: Hash; chain: Chain } | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const { address } = useCustomWallet();

    const [
      isAlertNotificationOpen,
      openAlertNotification,
      closeAlertNotification,
    ] = useAlertNotification();

    const handleChangeSelector = (amountSelected: number): void => {
      setAmount(amountSelected);

      if (error instanceof LimitMinError && amountSelected > 0) {
        setError(null);
      }
    };

    const handleSubmit = (event: FormEvent) => {
      event.preventDefault();

      if (address && amount) {
        handleMint();
      } else if (amount === 0) {
        setError(new LimitMinError("Invalid amount, please select 1 or more"));
      }
    };

    useUpdateEffect(() => {
      if (tx) {
        onNewTransaction(tx);

        toggleModal(false);
      }
    }, [tx]);

    useUpdateEffect(() => {
      setTx(null);
      setError(null);
    }, [address]);

    useUpdateEffect(() => {
      if (error && !isAlertNotificationOpen) {
        if (error instanceof UserDeniedTransactionSignatureError) {
          openAlertNotification(
            "error",
            lifeCycleLiterals.general.userRejectedTx,
            5000
          );
        } else if (
          !(
            error instanceof LimitMinError ||
            error instanceof LimitMaxError ||
            error instanceof GasLimitError
          )
        ) {
          openAlertNotification(
            "error",
            "Unexpected error on mint faucet signature",
            5000
          );
        }
      }
    }, [error]);

    const handleMint = async () => {
      try {
        await faucetModule.mintFaucet(collection, amount!, {
          onSignaturePending: () =>
            openAlertNotification("info", approveInWalletLiteral, 50000),
          onLoading: (hash) => {
            closeAlertNotification();
            setTx({ hash: hash || "0x", chain: collection.chain });
            setAmount(null);
          },
        });

        onMinted?.();
      } catch (err) {
        closeAlertNotification();
        if (err instanceof LimitMaxError) {
          err.message = "Invalid amount, please select 1 or more";

          setError(err);
        } else if (err instanceof LimitMaxError) {
          err.message = "Invalid amount";

          setError(err);
        } else if (err instanceof GasLimitError) {
          err.message = "User has not enough eth for gas";

          setError(err);

          // @ts-ignore
        } else if (err.code === 4001 || err.code === -32000) {
          setError(
            new UserDeniedTransactionSignatureError(
              "user denied transaction signature"
            )
          );
        } else {
          setError(
            new UnexpectedError("mint faucet signature unexpected error")
          );
        }
      }
    };

    return (
      <>
        <div
          className="h-fit top-1/2 -translate-y-1/2 bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
           border-2 rounded-3xl xs:px-12 px-8 pb-6 pt-8 xs:pb-8 w-96 text-white"
        >
          <button
            className="w-11 h-11 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5"
            onClick={() => toggleModal(false)}
          >
            <IoMdClose className="w-full h-full" />
          </button>
          <h5 className="xs:mb-5 mb-3 text-center text-base xs:text-lg font-bold">
            NFT Minting
          </h5>
          <div className="w-80 text-center text-xs xs:text-sm max-w-full xs:mb-5 mb-3">
            <RichText value="Select how many NFTs you want to mint" />
          </div>
          <form onSubmit={handleSubmit}>
            <Selector
              onChange={handleChangeSelector}
              max={availableNfts}
              className="xs:mb-8 mb-5"
              nameInput="totalMint"
            />
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
            <CtaPrimary className="w-full" type="submit">
              MINT
            </CtaPrimary>
          </form>
        </div>
      </>
    );
  }
);
