import { FC } from "react";
import { BasicProps } from "../../types/BasicProps";
import classNames from "classnames";
import displayHealthFactor from "../../../logic/helpers/displayHealthFactor";
import { HealthFactorCircle } from "../../HealthFactorCircle";
import TokenIcon from "components/icons/TokenIcon";
import { IErc20Currency } from "logic/types/currency/ICurrency";
import { generalLiterals } from "literals";

type Props = BasicProps & {
  healthFactor: bigint;
  borrowAmount: bigint;
  currency: IErc20Currency;
};

const {
  modals: {
    buyNow: { debtToTakeLabel, healthFactorLabel },
  },
} = generalLiterals;

const BorrowAmountAndHealthFactor: FC<Props> = ({
  className,
  healthFactor,
  borrowAmount,
  currency,
}) => {
  return (
    <div className={classNames("w-full flex flex-col items-center", className)}>
      <div className="flex items-center gap-1">
        <span>{debtToTakeLabel}</span>
        <span className="font-bold">
          {currency.formatAmount(
            borrowAmount > BigInt(0) ? borrowAmount : BigInt(0)
          )}
        </span>
        <TokenIcon currency={currency} className="w-4 h-4" />
      </div>
      <div className="flex items-center gap-1">
        <span>{healthFactorLabel}</span>
        <span className="font-bold">{displayHealthFactor(healthFactor!)}</span>
        <HealthFactorCircle healthFactor={healthFactor} />
      </div>
    </div>
  );
};

export default BorrowAmountAndHealthFactor;
