import { type FC, type PropsWithChildren } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import classNames from "classnames";
import { BasicProps } from "./types/BasicProps";
import Link from "next/link";

type InfoType = FC<
  PropsWithChildren & { iconClassName?: string; contentClassName?: string }
> & {
  Icon?: FC<BasicProps>;
  Title: FC<PropsWithChildren>;
  Description: FC<PropsWithChildren>;
  Link: FC<PropsWithChildren & { href: string }>;
};

export const Info: InfoType = ({
  children,
  iconClassName,
  contentClassName,
}) => {
  return (
    <div className="relative group flex items-center justify-center gap-0.5 sm:gap-1">
      <AiOutlineInfoCircle className={classNames(iconClassName)} />
      <div className="hidden group-hover:block absolute top-4 left-4">
        <div
          className={classNames(
            "z-30 absolute -left-[50%] sm:left-2 top-2 sm:top-0 transform -translate-x-[56%] sm:translate-x-0 sm:-translate-y-1/2 w-40 sm:w-60 rounded-lg border border-white bg-secondary pt-2 px-4 pb-2.5 flex flex-col gap-1 before:absolute before:-top-2 sm:before:top-0 before:left-0 sm:before:-left-2 before:content-[''] before:w-full sm:before:w-2 before:h-2 sm:before:h-full before:bg-transparent",
            contentClassName
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

Info.Title = ({ children }) => {
  return (
    <h4 className="w-full text-left text-[8px] sm:text-[10px] font-normal text-white">
      {children}
    </h4>
  );
};

Info.Description = ({ children }) => {
  return (
    <p className="w-full text-left text-[8px] font-normal sm:text-xs text-white">
      {children}
    </p>
  );
};

Info.Link = ({ children, href }) => {
  return (
    <Link
      className="text-left text-[8px] sm:text-[10px] text-primary"
      href={href}
      target="_blank"
    >
      {children}
    </Link>
  );
};
