import { BasicProps } from "components/types/BasicProps";
import { ICurrency } from "logic/types/currency/ICurrency";
import Image from "next/image";
import { FC } from "react";

type Props = BasicProps & {
  currency: ICurrency;
  balance: bigint;
};

export const TokenDropdownElement: FC<Props> = ({ currency, balance }) => {
  return (
    <div className="py-1.5 px-6 flex items-center gap-4">
      <Image
        src={currency.srcSecondary}
        width={40}
        height={40}
        alt={currency.label}
      />
      <div className="flex flex-col">
        <span className="font-bold leading-[20px]">{currency.label}</span>
        <span className="font-medium leading-[20px]">{`Balance: ${currency.formatAmount(
          balance
        )}`}</span>
      </div>
    </div>
  );
};
