"use client";
import { type FC, FormEvent, useState, useMemo } from "react";
import { CtaSecondary, CtaPrimary } from "..";
import useAlertNotification from "../hooks/useAlertNotification";
import { handleErrors } from "../../errors";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { generalLiterals, lifeCycleLiterals } from "../../literals";
import {
  useActionProtected,
  useAllowanceV2,
  useContractAddress,
  useErrorAlert,
  usePromiseProperty,
} from "../../logic/hooks";
import { GenericModal } from "./GenericModal";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Address } from "viem";
import TokenIcon from "components/icons/TokenIcon";
import { ModalProps } from "components/types/ModalProps";
import { useTokenBalance } from "components/providers/BalanceProvider";
import { Loan } from "logic/types/loan/Loan";

const {
  subheader,
  title,
  approveCta,
  redeemCta,
  backCta,
  notEnoughBalanceCta,
} = generalLiterals.modals.redeem;

const { onSignPending, onSuccess, onTxPending } =
  lifeCycleLiterals.approvalRedeem;

type Props = ModalProps & {
  item: Loan;
  onRedeemed?: (loanId: Address) => void;
};

export const ModalRedeem: FC<Props> = (props: Props) => {
  const { toggleModal, item, isOpen, onRedeemed } = props;
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { balance } = useTokenBalance(item.currency.id);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [, openAlertNotification] = useAlertNotification();
  const bidFine = usePromiseProperty("bidFine", item);
  const redeemAmount = usePromiseProperty("redeemAmount", item);

  const notEnoughBalance = useMemo(
    () =>
      balance !== undefined &&
      redeemAmount !== undefined &&
      balance < redeemAmount,
    [balance, redeemAmount]
  );

  const amountToApprove = useMemo(
    () =>
      redeemAmount ? (redeemAmount * BigInt(101)) / BigInt(100) : undefined,
    [redeemAmount]
  );

  const isModalReady = useMemo(
    () => amountToApprove !== undefined,
    [amountToApprove]
  );

  const unlockdAddress = useContractAddress("unlockd");

  const { isApproved, handleApprove: _handleApprove } = useAllowanceV2(
    amountToApprove || BigInt(0),
    unlockdAddress,
    item.currency
  );

  const ctaPrimaryLiteral = useMemo<string>(
    () =>
      notEnoughBalance
        ? notEnoughBalanceCta
        : isApproved
          ? redeemCta
          : approveCta,
    [isApproved, notEnoughBalance]
  );

  const ctaPrimaryDisabled = useMemo<boolean>(
    () => !isModalReady || isLoading || notEnoughBalance,
    [isModalReady, isLoading, notEnoughBalance]
  );

  const handleApprove = async () => {
    if (amountToApprove !== undefined) {
      try {
        await _handleApprove(undefined, {
          onSignaturePending: () => {
            setLoading(true);
            openAlertNotification("info", onSignPending, 5000000);
          },
          onLoading: () => {
            openAlertNotification("info", onTxPending, 5000000);
          },
        });

        openAlertNotification("success", onSuccess, 500);
      } catch (err) {
        setError(handleErrors(err, "approvalRedeem"));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRedeem = useActionProtected(async () => {
    if (amountToApprove !== undefined && redeemAmount != undefined) {
      try {
        await item.redeem({
          onServerSignPending: () => setLoading(true),
        });

        onRedeemed?.(item.id);

        toggleModal(false);
      } catch (err) {
        setError(handleErrors(err));
        setLoading(false);
      }
    }
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (!isLoading) {
      if (isApproved) {
        handleRedeem();
      } else {
        handleApprove();
      }
    }
  };

  return (
    <GenericModal
      className="w-fit"
      headerTitle={title(item.currency.label)}
      isOpen={isOpen}
      toggleModal={toggleModal}
      headerSubtitle={subheader}
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <div className="mt-[30px] h-[78px] flex justify-center items-center">
          <AiOutlineLoading3Quarters className="animate-spin w-10 h-10" />
        </div>
      ) : (
        <>
          <div className="mt-[30px] border border-myred rounded-2xl py-2.5 px-5 flex justify-center items-center gap-1">
            <AiOutlineInfoCircle className="w-2.5 h-2.5" />
            <p className="text-xs font-medium">
              Penalty for staying under 1 HF:{" "}
              <span className="font-bold">
                {bidFine !== undefined
                  ? item.currency.formatAmount(bidFine)
                  : "-.--"}
                {` ${item.currency.label}`}
              </span>
            </p>
          </div>

          <div className="mt-4 flex justify-center items-center gap-1">
            Total amount to repay:{" "}
            <span className="font-bold">
              {redeemAmount ? item.currency.formatAmount(redeemAmount) : "-.--"}
            </span>
            <TokenIcon currency={item.currency} className="w-4 h-4" />
          </div>
        </>
      )}

      <div className="mt-[30px] flex justify-between gap-4">
        <CtaSecondary
          className="w-[170px]"
          type="button"
          onClick={() => toggleModal(false)}
        >
          {backCta}
        </CtaSecondary>
        <CtaPrimary className="w-[170px]" disabled={ctaPrimaryDisabled}>
          {ctaPrimaryLiteral}
        </CtaPrimary>
      </div>
    </GenericModal>
  );
};
