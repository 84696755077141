import { FC } from "react";

export const IconLoan: FC<{ color: "white" | "uyellow" }> = ({ color }) => {
  const _color = color === "white" ? "white" : "#FFCE16";

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <g clipPath="url(#clip0_675_17132)">
        <path
          d="M23.7698 25.153C30.6495 25.153 36.2466 19.5112 36.2466 12.5765C36.2466 5.64191 30.6495 0 23.7698 0C16.8901 0 11.293 5.64183 11.293 12.5765C11.293 19.5111 16.8901 25.153 23.7698 25.153ZM19.7606 16.501C20.1235 15.9462 20.8676 15.7905 21.4223 16.1536C22.2308 16.6823 22.5357 16.7311 23.6025 16.7236C24.6436 16.7168 25.2478 15.9407 25.3685 15.2225C25.4272 14.8732 25.4497 14.0201 24.3925 13.6464C23.1524 13.208 21.8833 12.7069 21.0004 12.0144C20.1175 11.3219 19.7132 10.1264 19.9454 8.89466C20.1971 7.55936 21.1286 6.49639 22.3764 6.12057C22.3877 6.11721 22.3987 6.11449 22.41 6.11113V5.65607C22.41 4.9931 22.9475 4.45562 23.6105 4.45562C24.2734 4.45562 24.8109 4.9931 24.8109 5.65607V6.0355C25.6263 6.23013 26.1957 6.60315 26.4269 6.77594C26.9579 7.17297 27.0666 7.92518 26.6696 8.45626C26.2727 8.98734 25.5205 9.09602 24.9894 8.69891C24.7434 8.515 24.0635 8.11965 23.0688 8.41936C22.4877 8.59447 22.337 9.16781 22.3047 9.33931C22.2412 9.67632 22.3124 9.9922 22.4821 10.1252C23.0941 10.6052 24.1958 11.0303 25.1927 11.3827C27.0309 12.0324 28.053 13.7355 27.7362 15.6205C27.5807 16.5454 27.1154 17.4035 26.4259 18.0369C25.9563 18.4685 25.4097 18.7761 24.8109 18.9518V19.4969C24.8109 20.1598 24.2734 20.6973 23.6105 20.6973C22.9475 20.6973 22.41 20.1598 22.41 19.4969V19.0715C21.6338 18.9774 20.9822 18.7346 20.1079 18.1628C19.5532 17.7998 19.3977 17.0558 19.7606 16.501Z"
          fill={_color}
        />
        <path
          d="M4.55094 29.1865H1.81374C1.15077 29.1865 0.613281 29.724 0.613281 30.387V39.7978C0.613281 40.4607 1.15077 40.9982 1.81374 40.9982H4.55102L4.55094 29.1865Z"
          fill={_color}
        />
        <path
          d="M40.0377 29.1031C37.7877 26.8531 34.1266 26.853 31.8767 29.1031L28.2806 32.6991L26.8067 34.173C26.211 34.7687 25.403 35.1034 24.5606 35.1034H17.3936C16.7463 35.1034 16.1893 34.6059 16.1588 33.9594C16.1263 33.269 16.6763 32.6991 17.3596 32.6991H24.6444C26.1091 32.6991 27.3795 31.6563 27.6313 30.2135C27.6892 29.8821 27.7194 29.5414 27.7194 29.1938C27.7194 28.5296 27.1813 27.9907 26.5172 27.9907H22.5255C21.221 27.9907 19.9683 27.3989 18.642 26.7723C17.2508 26.1152 15.8123 25.4356 14.1303 25.3238C12.6592 25.2257 11.1847 25.3868 9.74763 25.8018C8.20888 26.2461 7.10278 27.6132 6.96865 29.1915C6.96353 29.1911 6.95833 29.1911 6.95312 29.1907V40.9945L27.6352 41.0002C29.0571 41.0002 30.3941 40.4464 31.3998 39.4408L40.0375 30.8031C40.5072 30.3338 40.5072 29.5726 40.0377 29.1031Z"
          fill={_color}
        />
      </g>
      <defs>
        <clipPath id="clip0_675_17132">
          <rect width="41" height="41" fill={_color} />
        </clipPath>
      </defs>
    </svg>
  );
};
