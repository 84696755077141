"use client";
import { type FC, FormEvent, useEffect, useState, useMemo } from "react";
import { CtaSecondary, CtaPrimary } from "../..";
import useAlertNotification from "../../hooks/useAlertNotification";
import { handleErrors } from "../../../errors";
import { MarketItem } from "../../../logic/types";
import { GenericModal } from "../GenericModal";
import SquareInput from "../../marketplace/list-for-sale/SquareInput";
import { IconGrocery } from "../../icons/IconGrocery";
import { IconLoan } from "../../icons/IconLoan";
import NftImage from "../../images/NftImage";
import DataField from "../../DataField";
import Input from "../shared/Input";
import { useModalBidDataItems } from "./helpers";
import {
  approveInWalletLiteral,
  generalLiterals,
  lifeCycleLiterals,
} from "../../../literals";
import classNames from "classnames";
import BorrowAmountAndHealthFactor from "../shared/BorrowAmountAndHealthFactor";
import LoadingSpinner from "../../LoadingSpinner";
import InputErrorMessage from "../shared/InputErrorMessage";
import { useCustomWallet } from "../../providers";
import { deployUnlockdWallet } from "../../../logic/wallet";
import { HandleBidPlaced } from "../../../app/marketplace/buy-nfts/page";
import { useUpdateEffect } from "react-use";
import { useWeb3ModalGuard } from "../shared";
import { useTokenBalance } from "../../providers/BalanceProvider";
import { ModalProps } from "components/types/ModalProps";
import { calculateHealthFactor } from "logic/helpers/math";
import {
  useAllowance,
  useContractAddress,
  useErrorAlert,
  usePromiseProperty,
} from "logic/hooks";
import track from "logic/track";
import { useMinBid } from "logic/hooks/marketplace/useMinBid";

type Props = ModalProps & {
  marketItem: MarketItem;
  wasRemoved: boolean;
  onBidPlaced?: HandleBidPlaced;
};

enum Status {
  CREATE_WALLET,
  BID,
  APPROVE,
  REDEEMED,
}

export enum WayToPayRadioOptions {
  FULL_PRICE = "FULL_PRICE",
  TAKE_LOAN = "TAKE_LOAN",
}

const {
  bidAmountInputLabel,
  totalBidAmountInputLabel,
  initialPaymentInputLabel,
  ctaPrimaryCreateWallet,
  ctaPrimaryApprove,
  ctaPrimaryInvalidBid,
  ctaPrimaryBid,
  payFullPriceLabel,
  payLaterLabel,
  subheader,
  title,
} = generalLiterals.modals.bid;

export const ModalBid: FC<Props> = (props) => {
  const { isOpen, marketItem, toggleModal, wasRemoved, onBidPlaced } = props;
  const { balance, forceBalanceUpdate } = useTokenBalance(
    marketItem.currency.id
  );
  const [amountToBid, setAmountToBid] = useState<bigint>(BigInt(0));
  const { address, hasSmartAddress, onUnlockdWalletCreated } =
    useCustomWallet();
  const [initialPayment, setInitialPayment] = useState<bigint>(BigInt(0));
  const [errorOnBidAmount, setErrorOnBidAmount] = useState<Error | null>(null);
  const [errorOnInitialPayment, setErrorOnInitialPayment] =
    useState<Error | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<Status>(Status.APPROVE);
  const [, openAlertNotification] = useAlertNotification();
  const [radioSelected, setRadioSelected] = useState<WayToPayRadioOptions>(
    WayToPayRadioOptions.FULL_PRICE
  );
  const [minInitialPayment, setMinInitialPayment] = useState<bigint>(BigInt(0));
  const valuation = usePromiseProperty("valuation", marketItem.nft);
  const liquidationThreshold = usePromiseProperty(
    "liquidationThreshold",
    marketItem.nft
  );
  const minBid = useMinBid(marketItem);

  const bidInputStyle = useMemo<string>(
    () => (!!errorOnBidAmount ? "border-myred" : ""),
    [errorOnBidAmount]
  );

  const initialPaymentInputStyle = useMemo<string>(
    () => (!!errorOnInitialPayment ? "border-myred" : ""),
    [errorOnInitialPayment]
  );

  const amountToPay = useMemo<bigint>(
    () =>
      radioSelected === WayToPayRadioOptions.FULL_PRICE
        ? amountToBid
        : initialPayment,
    [amountToBid, initialPayment]
  );

  const amountToBorrow = useMemo<bigint | undefined>(
    () =>
      radioSelected === WayToPayRadioOptions.FULL_PRICE
        ? undefined
        : amountToBid - initialPayment,
    [amountToBid, initialPayment, radioSelected]
  );

  const readOnlyInitialPayment = useMemo<boolean>(
    () => amountToBid === undefined || amountToBid === BigInt(0),
    [amountToBid]
  );

  const unlockdAddress = useContractAddress("unlockd");

  const {
    allowance,
    handleApprove: _handleApprove,
    isApproved,
  } = useAllowance({
    amount: amountToPay,
    spender: unlockdAddress,
    erc20Currency: marketItem.currency,
  });

  const dataFieldItems = useModalBidDataItems(
    radioSelected,
    marketItem,
    minInitialPayment
  );

  const healthFactor = useMemo<bigint | undefined>(
    () =>
      radioSelected === WayToPayRadioOptions.FULL_PRICE ||
      amountToBorrow === undefined ||
      valuation === undefined ||
      liquidationThreshold === undefined
        ? undefined
        : calculateHealthFactor(
            valuation,
            amountToBorrow,
            liquidationThreshold
          ),
    [radioSelected, marketItem, amountToBorrow, valuation, liquidationThreshold]
  );

  const panelStyles = useMemo<string>(
    () => (dataFieldItems.length > 4 ? "grid-cols-3" : "grid-cols-4"),
    [dataFieldItems]
  );

  const bidInputLabel = useMemo<string>(
    () =>
      radioSelected === WayToPayRadioOptions.TAKE_LOAN
        ? totalBidAmountInputLabel
        : bidAmountInputLabel,
    [radioSelected]
  );

  const invalidBid = useMemo<boolean>(
    () =>
      !!error ||
      !!errorOnBidAmount ||
      (radioSelected === WayToPayRadioOptions.TAKE_LOAN &&
        !!errorOnInitialPayment),
    [error, errorOnBidAmount, errorOnInitialPayment, radioSelected]
  );

  const isCtaPrimaryDisabled = useMemo<boolean>(
    () => isLoading || invalidBid,
    [isLoading, invalidBid]
  );

  const ctaPrimaryLiteral = useMemo(
    () =>
      invalidBid
        ? ctaPrimaryInvalidBid
        : status === Status.CREATE_WALLET
        ? ctaPrimaryCreateWallet
        : status === Status.APPROVE
        ? ctaPrimaryApprove
        : ctaPrimaryBid,
    [status, invalidBid]
  );

  // handle status
  useEffect(() => {
    if (radioSelected === WayToPayRadioOptions.TAKE_LOAN && !hasSmartAddress) {
      setStatus(Status.CREATE_WALLET);
    } else if (allowance !== undefined && amountToPay !== undefined) {
      if (isApproved) {
        setStatus(Status.BID);
      } else {
        setStatus(Status.APPROVE);
      }
    }
  }, [allowance, isApproved, hasSmartAddress, radioSelected]);

  useEffect(() => {
    handleSetMinInitialPayment();
  }, [amountToBid, radioSelected]);

  useEffect(() => {
    minBid !== undefined && setAmountToBid(minBid);
  }, [minBid]);

  useUpdateEffect(() => {
    minBid !== undefined && runAmountToBidValidation();
  }, [minBid]);

  useEffect(() => {
    runAmountToBidValidation(amountToBid);

    radioSelected === WayToPayRadioOptions.TAKE_LOAN &&
      runInitialPaymentValidation(initialPayment);
  }, [radioSelected, initialPayment, amountToBid, balance]);

  // useUpdateEffect(() => {
  //   if (address && !equalIgnoreCase(marketItem.bidder!, address)) {
  //     openAlertNotification("info", bidPlacedLiteral, 5000);
  //   }
  // }, [marketItem.latestBid]);

  // useUpdateEffect(() => {
  //   if (amount < marketItem.minBid)
  //     setError(
  //       new LimitMinError(
  //         `amount ${formatWeiToEth(amount)} is lower than the minimum required`
  //       )
  //     );
  // }, [marketItem.minBid]);

  // useUpdateEffect(() => {
  //   if (wasRemoved) {
  //     openAlertNotification("info", redeemPlacedLiteral, 5000);

  //     setStatus(Status.REDEEMED);
  //   }
  // }, [wasRemoved]);

  const handleRadioChange = (value: string) => {
    setRadioSelected(value as WayToPayRadioOptions);
  };

  const handleAmountToBidChange = (amountSelected: bigint): void => {
    setAmountToBid(amountSelected);

    runAmountToBidValidation(amountSelected);
  };

  const handleSetMinInitialPayment = async () => {
    const minInitialPayment =
      amountToBid === undefined
        ? BigInt(0)
        : await marketItem.calculateMinInitialPayment(amountToBid);

    setMinInitialPayment(minInitialPayment);
  };

  const runAmountToBidValidation = (_amountSelected?: bigint) => {
    const amountSelected = _amountSelected || amountToBid;

    if (
      balance !== undefined &&
      radioSelected === WayToPayRadioOptions.FULL_PRICE &&
      amountSelected > balance
    ) {
      setErrorOnBidAmount(
        new Error(lifeCycleLiterals.placeABid.bidExceedsBalance)
      );
    } else if (minBid !== undefined && amountSelected < minBid) {
      setErrorOnBidAmount(
        new Error(lifeCycleLiterals.placeABid.bidLessThanMin)
      );
    } else {
      if (errorOnBidAmount) setErrorOnBidAmount(null);
    }
  };

  const handleInitialPaymentChange = (amountSelected: bigint): void => {
    setInitialPayment(amountSelected);

    runInitialPaymentValidation(amountSelected);
  };

  const runInitialPaymentValidation = (amountSelected: bigint) => {
    if (amountSelected > amountToBid) {
      setErrorOnInitialPayment(
        new Error(lifeCycleLiterals.placeABid.initialPayExceedsBid)
      );
    } else if (balance !== undefined && amountSelected > balance) {
      setErrorOnInitialPayment(
        new Error(lifeCycleLiterals.placeABid.initialPaymentExceedsBalance)
      );
    } else if (amountSelected < minInitialPayment) {
      setErrorOnInitialPayment(
        new Error(lifeCycleLiterals.placeABid.initialPayLessThanMin)
      );
    } else {
      if (errorOnInitialPayment) setErrorOnInitialPayment(null);
    }
  };

  const handleCreateWallet = async () => {
    try {
      if (address) {
        const safeAddress = await deployUnlockdWallet(address, {
          onSignaturePending: () => {
            setLoading(true);
            openAlertNotification(
              "info",
              lifeCycleLiterals.createWallet.onSignPending,
              5000000
            );
          },
          onLoading: () => {
            openAlertNotification(
              "info",
              lifeCycleLiterals.createWallet.onTxPending,
              5000000
            );
          },
        });

        onUnlockdWalletCreated?.(safeAddress);

        openAlertNotification(
          "success",
          lifeCycleLiterals.createWallet.onSuccess,
          5000
        );
      }
    } catch (err) {
      setError(handleErrors(err, "createWallet"));
    } finally {
      setLoading(false);

      forceBalanceUpdate();
    }
  };

  const handleApprove = async () => {
    try {
      await _handleApprove(undefined, {
        onSignaturePending: () => {
          setLoading(true);
          openAlertNotification("info", approveInWalletLiteral, 5000);
        },
      });
    } catch (err) {
      setError(handleErrors(err));
    } finally {
      setLoading(false);
      forceBalanceUpdate();
    }
  };

  const handleBid = useWeb3ModalGuard(undefined, async () => {
    try {
      const amountToPay =
        radioSelected === WayToPayRadioOptions.TAKE_LOAN
          ? initialPayment
          : amountToBid;

      const amountOfDebt =
        radioSelected === WayToPayRadioOptions.FULL_PRICE
          ? BigInt(0)
          : amountToBid - initialPayment;

      const orderId = await marketItem.placeABid(amountToPay, amountOfDebt, {
        onServerSignPending: () => {
          setLoading(true);
          openAlertNotification(
            "info",
            lifeCycleLiterals.placeABid.onServerSignPending,
            5000000
          );
        },
        onSignaturePending: () => {
          openAlertNotification(
            "info",
            lifeCycleLiterals.placeABid.onSignPending,
            5000000
          );
        },
        onLoading: () => {
          openAlertNotification(
            "info",
            lifeCycleLiterals.placeABid.onTxPending,
            5000000
          );
        },
      });

      track.event("bid", "mktp_bid_fullprice_success");

      openAlertNotification(
        "success",
        lifeCycleLiterals.placeABid.onSuccess,
        5000
      );

      onBidPlaced?.(
        orderId,
        marketItem.nft.assetId,
        marketItem.isItemListed,
        address!,
        amountToBid
      );

      toggleModal(false);
    } catch (err) {
      track.event("bid_failure", "mktp_bid_fullprice_fail");

      setError(handleErrors(err, "placeABid"));
    } finally {
      forceBalanceUpdate();
      setLoading(false);
    }
  });

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (
      !isLoading &&
      amountToBid !== undefined &&
      amountToBid !== BigInt(0) &&
      allowance !== undefined &&
      !invalidBid
    ) {
      if (status === Status.CREATE_WALLET) {
        handleCreateWallet();
      } else if (status === Status.APPROVE) {
        handleApprove();
      } else {
        handleBid();
      }
    }
  };

  return (
    <GenericModal
      onSubmit={handleSubmit}
      isOpen={isOpen}
      toggleModal={toggleModal}
      headerTitle={title}
      headerSubtitle={subheader}
    >
      {isLoading ? (
        <div className="my-24 h-12 flex items-center justify-center">
          <LoadingSpinner className="w-10 h-10" />
        </div>
      ) : (
        <>
          <div className="mt-5 flex justify-center gap-3.5">
            <SquareInput
              className="w-[123px] h-[89px]"
              Icon={IconGrocery}
              isSelected={radioSelected === WayToPayRadioOptions.FULL_PRICE}
              label={payFullPriceLabel}
              onSelected={handleRadioChange}
              value={WayToPayRadioOptions.FULL_PRICE}
              type="radio"
              circleHidden={true}
            />
            <SquareInput
              className="w-[123px] h-[89px]"
              Icon={IconLoan}
              isSelected={radioSelected === WayToPayRadioOptions.TAKE_LOAN}
              label={payLaterLabel}
              onSelected={handleRadioChange}
              value={WayToPayRadioOptions.TAKE_LOAN}
              type="radio"
              circleHidden={true}
            />
          </div>
          <div className="mt-5 w-full border-2 border-white rounded-xl py-3.5 pr-[30px] pl-7 flex justify-center items-center gap-10">
            <NftImage
              className="w-[55px] h-[55px] rounded-[10px] shrink-0"
              nft={marketItem.nft}
            />
            <div
              className={classNames(
                "grow-[1] grid grid-cols-3 gap-3",
                panelStyles
              )}
            >
              {dataFieldItems.map((item) => (
                <DataField
                  key={item.label}
                  className="w-[100px] overflow-hidden whitespace-nowrap items-start"
                  item={item}
                />
              ))}
            </div>
          </div>
          <div className="mt-4 w-full flex justify-center gap-[22px]">
            <div className="w-full flex flex-col gap-1">
              <Input
                className={classNames("w-full", bidInputStyle)}
                nameInput={bidInputLabel}
                label={bidInputLabel}
                value={amountToBid}
                defaultValue={amountToBid}
                onChange={handleAmountToBidChange}
                maxButtonHidden={true}
                currency={marketItem.currency}
              />
              <div className="mt-2 h-2.5">
                {errorOnBidAmount && (
                  <InputErrorMessage error={errorOnBidAmount} />
                )}
              </div>
            </div>
            {radioSelected === WayToPayRadioOptions.TAKE_LOAN && (
              <div className="w-full flex flex-col gap-1">
                <Input
                  className={classNames("w-full", initialPaymentInputStyle)}
                  nameInput={initialPaymentInputLabel}
                  label={initialPaymentInputLabel}
                  value={initialPayment}
                  defaultValue={initialPayment}
                  // max={balance}
                  maxButtonHidden={true}
                  readOnly={readOnlyInitialPayment}
                  onChange={handleInitialPaymentChange}
                  currency={marketItem.currency}
                />
                {
                  <div className="mt-2 h-2.5">
                    {errorOnInitialPayment && (
                      <InputErrorMessage error={errorOnInitialPayment} />
                    )}
                  </div>
                }
              </div>
            )}
          </div>
          {radioSelected === WayToPayRadioOptions.TAKE_LOAN && (
            <BorrowAmountAndHealthFactor
              className="mt-3 gap-4"
              currency={marketItem.currency}
              borrowAmount={amountToBorrow!}
              healthFactor={healthFactor!}
            />
          )}
        </>
      )}

      <div className="mt-6 flex justify-center gap-6">
        <CtaSecondary
          className="w-[190px]"
          type="button"
          onClick={() => toggleModal(false)}
        >
          Cancel
        </CtaSecondary>
        <CtaPrimary
          className="w-[190px]"
          type="submit"
          disabled={isCtaPrimaryDisabled}
        >
          {ctaPrimaryLiteral}
        </CtaPrimary>
      </div>
    </GenericModal>
  );
};
