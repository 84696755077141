import { AiOutlineLoading3Quarters } from "react-icons/ai";
import classNames from "classnames";
import { FC } from "react";
import { BasicProps } from "./types/BasicProps";

const LoadingSpinner: FC<BasicProps> = ({ className }) => {
  return (
    <AiOutlineLoading3Quarters
      className={classNames("animate-spin", className)}
    />
  );
};

export default LoadingSpinner;
