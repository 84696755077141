import { BasicProps } from "components/types/BasicProps";
import Image from "next/image";
import { FC } from "react";

type Props = BasicProps & {
  label: string;
  imageSrc: string;
};

export const WalletDropdownElement: FC<Props> = ({ label, imageSrc }) => {
  return (
    <div className="px-6 py-1 flex items-center gap-4">
      <Image src={imageSrc} width={35} height={35} alt={label} />
      <span className="text-2xl font-bold">{label}</span>
    </div>
  );
};
