import { Address } from "viem";
import { type NetworkConfig } from "../config/app.config";
import { CONSTANTS, PushAPI } from "@pushprotocol/restapi";
import {
  SupportedChainNames,
  externalWalletModule,
  verisModule,
} from "../clients/verisModule";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";

class PushNotificationsModule {
  private pushChannels: Map<SupportedChainNames, Address> = new Map();
  private pushAPIs: Map<SupportedChainNames, PushAPI> = new Map();

  constructor(networks: NetworkConfig[]) {
    networks.forEach(
      ({ CHAIN: { name: chainName }, CONTRACT_ADDRESSES: { pushChannel } }) => {
        this.pushChannels.set(chainName, pushChannel);
      }
    );
  }

  private get chain() {
    return verisModule.currentNetworkConfig.CHAIN;
  }

  private get pushChannel() {
    const pushChannel = this.pushChannels.get(this.chain.name);

    if (!pushChannel) {
      throw new Error("Push channel not found");
    }

    return pushChannel;
  }

  private get channelAddress() {
    return `eip155:${this.chain.id}:${this.pushChannel}`;
  }

  private get eipPrefix() {
    return `eip155:${this.chain.id}`;
  }

  private getUserEip(userAddress: Address) {
    return `${this.eipPrefix}${userAddress}`;
  }

  private get env() {
    return this.chain.name !== "mainnet"
      ? CONSTANTS.ENV.STAGING
      : CONSTANTS.ENV.PROD;
  }

  private get pushAPI() {
    return this.pushAPIs.get(this.chain.name);
  }

  private async getPushAPI() {
    const extWallet = externalWalletModule.address;
    const extWalletClient = externalWalletModule.client;
    const chain = this.chain;

    if (!extWallet || !extWalletClient) {
      throw new Error("Client not connected");
    }

    let pushAPI = this.pushAPI;

    if (
      pushAPI &&
      // @ts-ignore
      equalIgnoreCase(pushAPI.account!, externalWalletModule.address!)
    ) {
      return pushAPI;
    }

    // @ts-ignore
    pushAPI = await PushAPI.initialize(extWalletClient, {
      env: this.env,
    });

    this.pushAPIs.set(chain.name, pushAPI);

    return pushAPI;
  }

  async isUserSubscribed() {
    const pushAPI = await this.getPushAPI();

    const subscriptions: { channel: Address; user_settings: string }[] =
      await pushAPI.notification.subscriptions();

    const isChannelInSubscriptions = subscriptions.some(({ channel }) =>
      equalIgnoreCase(channel, this.pushChannel)
    );

    return isChannelInSubscriptions;
  }

  async subscribe() {
    const pushAPI = await this.getPushAPI();

    const response = await pushAPI.notification.subscribe(this.channelAddress);

    if (response instanceof Error || response.status !== 204) {
      throw new Error("Failed to subscribe");
    }

    return;
  }

  async unsubscribe() {
    const pushAPI = await this.getPushAPI();

    const response = await pushAPI.notification.unsubscribe(
      this.channelAddress
    );

    if (response instanceof Error || response.status !== 204) {
      throw new Error("Failed to unsubscribe");
    }

    return;
  }
}

export default new PushNotificationsModule(verisModule.networks);
