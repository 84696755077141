"use client";
import { IoMdClose } from "react-icons/io";
import { type FC, useState } from "react";
import Image from "next/image";
import { Anchor, CtaSecondary, CtaPrimary } from "..";
import { withModal } from "./shared";
import loginOnUnlockdApi from "../../logic/loginOnUnlockdApi";
import { useCustomWallet } from "../providers";
import { handleErrors } from "../../errors";
import { useErrorAlert } from "../../logic/hooks";
import UnlockdService from "data/UnlockdService";
import { useChain } from "components/providers/ChainProvider";

interface Props {
  isOpen: boolean;
  toggleModal: (nextValue?: boolean) => void;
}

export const ModalSign: FC<Props> = withModal((props: Props) => {
  const { toggleModal } = props;
  const { address } = useCustomWallet();
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);
  const { chainId } = useChain();

  const signIn = async () => {
    if (address) {
      try {
        UnlockdService.get(chainId).token = await loginOnUnlockdApi(
          address,
          chainId
        );

        toggleModal(false);
      } catch (err) {
        setError(handleErrors(err));
      }
    }
  };

  return (
    <>
      <div className="fixed top-1/2 -translate-y-1/2 left-0 w-screen h-screen grid place-content-center z-50">
        <div
          onClick={() => toggleModal(false)}
          className="absolute w-full h-full -z-10 bg-drawer cursor-default"
        ></div>
        <div
          className="bg-secondary relative border-glow-sm max-w-[calc(100vw-24px-24px)] overflow-hidden
           border-2 rounded-3xl xs:px-6 px-5 pb-6 pt-8 xs:pb-8 w-96"
        >
          <button
            className="w-10 h-10 cursor-pointer absolute right-0 top-0 pt-3 pr-3 pb-1.5 pl-1.5 text-white"
            onClick={() => toggleModal(false)}
          >
            <IoMdClose className="w-full h-full" />
          </button>
          <h5 className="xs:mb-8 mb-5 text-center text-base xs:text-lg font-bold text-white">
            Welcome to Unlockd
          </h5>
          <div className="xs:mb-8 mb-5 grid place-content-center">
            <div className="relative w-44 xs:w-64">
              <Image
                alt="unlockd logo"
                height={300}
                width={1400}
                src="/assets/images/logo-unlockd.png"
              />
            </div>
          </div>
          <div className="w-80 text-center text-2xs xs:text-xs max-w-full xs:mb-8 mb-5 text-white">
            <span>
              By connecting your wallet and using Unlockd you agree to our{" "}
              <Anchor
                href="https://www.unlockd.finance/terms-of-use"
                target="_blank"
                className="underline text-blue-600"
              >
                Terms and Conditons
              </Anchor>{" "}
              and{" "}
              <Anchor
                href="https://www.unlockd.finance/privacy-policy"
                target="_blank"
                className="underline text-blue-600"
              >
                Privacy Policy
              </Anchor>
            </span>
          </div>
          {/* {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>} */}
          <div className="max-w-full xs:flex xs:flex-row-reverse justify-center">
            <CtaPrimary className="max-xs:w-full max-xs:mb-4" onClick={signIn}>
              Accept and Sign
            </CtaPrimary>
            <CtaSecondary
              className="xs:mr-4 max-xs:w-full"
              onClick={() => toggleModal(false)}
            >
              Cancel
            </CtaSecondary>
          </div>
        </div>
      </div>
    </>
  );
});
