import { MarketItem } from "logic/types";
import { usePromisePropertyV2 } from "../usePromisePropertyV2";
import { useEffect } from "react";

export const useMinBid = (marketItem: MarketItem): bigint | undefined => {
  const { value: minBid, updateValue: updateMinBid } = usePromisePropertyV2(
    "minBid",
    marketItem
  );

  useEffect(() => {
    updateMinBid();
  }, [marketItem.latestBid]);

  return minBid;
};
