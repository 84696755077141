import { FC, useMemo, useState } from "react";
import { BasicProps } from "../../../types/BasicProps";
import classNames from "classnames";
import { ArrowDown } from "./ArrowDown";

export type Option = {
  value: string;
  Content: JSX.Element;
  handleClick?: () => void;
  selected: boolean;
};

type Props = BasicProps & {
  options: Option[];
  openByDefault?: boolean;
  title: string;
  heightClassName: string;
  zIndexClassName?: string;
};

export const SelectionDropdown: FC<Props> = (props) => {
  const {
    className,
    options,
    openByDefault = false,
    title,
    heightClassName,
    zIndexClassName = "z-50",
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const optionsSorted = useMemo<Option[]>(() => {
    const index = options.findIndex((option) => option.selected);

    const optionsSorted = [...options];

    if (index !== -1) {
      const objetoSelected = optionsSorted.splice(index, 1)[0];
      optionsSorted.unshift(objetoSelected);
    }

    return optionsSorted;
  }, [options]);

  const handleDivClick = () => {
    if (!isDropdownOpen && !openByDefault) setIsDropdownOpen(true);
  };

  const handleOptionClick = (option: Option) => {
    option.handleClick && option.handleClick();
    !openByDefault && setIsDropdownOpen(false);
  };

  return (
    <div
      className={classNames("relative w-full rounded-xl", heightClassName)}
      onClick={handleDivClick}
    >
      <div
        className={classNames(
          "absolute top-0 left-0 border-2 border-white rounded-xl bg-secondary w-full flex flex-col justify-center",
          className,
          zIndexClassName
        )}
        onClick={handleDivClick}
      >
        <div
          className={classNames(
            "py-2.5 w-full flex flex-col justify-between",
            heightClassName
          )}
        >
          <div className="px-6 flex items-center gap-1">
            <span className="text-xs">{title}</span>
            {!openByDefault && <ArrowDown widthAndHeight={10} />}
          </div>
          <div
            key={optionsSorted[0].value}
            className={classNames(
              "flex items-center gap-4",
              (isDropdownOpen || openByDefault) && "bg-[#462EE180]"
            )}
            onClick={() => {
              handleOptionClick(optionsSorted[0]);
            }}
          >
            {optionsSorted[0].Content}
          </div>
        </div>
        {(isDropdownOpen || openByDefault) && (
          <div className="min-w-max mx-6 border border-white opacity-25"></div>
        )}
        <ul className="w-full">
          {(isDropdownOpen || openByDefault) &&
            optionsSorted.slice(1).map((option, index) => (
              <>
                <li
                  key={option.value}
                  className={classNames(
                    "my-2.5 flex items-center gap-4",
                    option.selected && isDropdownOpen && "bg-[#462EE180]"
                  )}
                  onClick={() => {
                    handleOptionClick(option);
                  }}
                >
                  {option.Content}
                </li>
                {index + 2 !== options.length && (
                  <div className="my-2.5 min-w-max mx-6 border border-white opacity-25"></div>
                )}
              </>
            ))}
        </ul>
      </div>
    </div>
  );
};
