"use client";
import Image from "next/image";
import { useEffect, useState, type FC, FormEvent } from "react";
import { CtaSecondary, CtaPrimary, CtaRed } from "..";
import { useCustomWallet } from "../providers";
import useAlertNotification from "../hooks/useAlertNotification";
import pushNotificationsModule from "../../logic/PushNotificationsModule";
import { handleErrors } from "../../errors";
import Link from "next/link";
import { GenericModal } from "./GenericModal";
import logic from "../../logic";
import { useErrorAlert } from "../../logic/hooks";
import { ModalProps } from "../types/ModalProps";
import { lifeCycleLiterals } from "../../literals";

export const ModalNotifications: FC<ModalProps> = (props) => {
  const { toggleModal, isOpen } = props;
  const { address } = useCustomWallet();
  const [isSubscribed, setSuscribed] = useState<boolean>();
  const [error, setError] = useState<Error | null>(null);
  const [, openAlertNotification] = useAlertNotification();
  useErrorAlert(error);

  useEffect(() => {
    address && handleGetIsSubscribed();
  }, [address]);

  const handleGetIsSubscribed = async () => {
    try {
      const isSubscribed = await pushNotificationsModule.isUserSubscribed();

      setSuscribed(isSubscribed);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleSubscribe = async () => {
    try {
      openAlertNotification(
        "info",
        lifeCycleLiterals.pushSubscribe.onSignPending,
        50000
      );

      await pushNotificationsModule.subscribe();

      setSuscribed(true);

      openAlertNotification(
        "success",
        lifeCycleLiterals.pushSubscribe.onSuccess,
        5000
      );
    } catch (err) {
      setError(handleErrors(err, "pushSubscribe"));
    }
  };

  const handleUnsubscribe = async () => {
    try {
      openAlertNotification(
        "info",
        lifeCycleLiterals.pushUnsubscribe.onSignPending,
        50000
      );

      await pushNotificationsModule.unsubscribe();

      setSuscribed(false);

      openAlertNotification(
        "success",
        lifeCycleLiterals.pushUnsubscribe.onSuccess,
        5000
      );
    } catch (err) {
      setError(handleErrors(err, "pushUnsubscribe"));
    }
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    if (isSubscribed) {
      handleUnsubscribe();
    } else {
      handleSubscribe();
    }
  };

  return (
    <GenericModal
      isOpen={isOpen}
      toggleModal={toggleModal}
      headerTitle="Notifications"
      onSubmit={handleSubmit}
    >
      <div className="mt-5 flex flex-col justify-center items-center gap-5 text-white">
        <span>
          By enabling push notifications, you'll be immediately notified when:
        </span>
        <ul className="list-disc">
          <li>
            Your NFT is up for auction due to liquidation of your position
          </li>
          <li>An auction in which you've placed a bid ends</li>
          <li>You are outbid by another bidder in an active auction</li>
          <li>A new auction becomes available for you to bid</li>
        </ul>
      </div>
      <div className="mt-7 flex flex-col justify-center items-center gap-5">
        {!isSubscribed ? (
          <CtaPrimary
            className="w-72 flex items-center justify-center gap-2"
            disabled={isSubscribed === undefined}
            type="submit"
          >
            <Image
              alt="Push icon"
              src="/assets/images/push-icon.svg"
              width="15"
              height="15"
            />
            Activate notifications
          </CtaPrimary>
        ) : (
          <CtaRed
            className="w-72 flex items-center justify-center gap-2"
            type="submit"
          >
            <Image
              alt="Push icon"
              src="/assets/images/push-icon.svg"
              width="15"
              height="15"
            />
            Disable notifications
          </CtaRed>
        )}
        <CtaSecondary className="w-72" type="button">
          <Link
            href="https://twitter.com/Unlockd_Finance"
            className="flex items-center justify-center gap-2"
            target="_blank"
          >
            <Image
              alt="Twitter"
              src="/assets/images/twitter.svg"
              width="15"
              height="15"
            />
            Follow us on Twitter
          </Link>
        </CtaSecondary>
      </div>
    </GenericModal>
  );
};
