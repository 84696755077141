import { FC } from "react";
import { BasicProps } from "../../types/BasicProps";
import { AiOutlineInfoCircle } from "react-icons/ai";
import classNames from "classnames";

type Props = BasicProps & {
  error: Error;
};

const InputErrorMessage: FC<Props> = (props) => {
  const { error, className } = props;

  return (
    <div className={classNames(className, "flex items-center gap-2")}>
      <AiOutlineInfoCircle className="w-2 h-2 text-myred" />
      <span className="text-[10px] text-myred">{error.message}</span>
    </div>
  );
};

export default InputErrorMessage;
