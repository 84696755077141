import { useMemo } from "react";
import { MarketItem } from "../../../logic/types";
import { WayToPayRadioOptions } from "./ModalBid";
import { DataItem } from "../../DataField";
import { generalLiterals } from "literals";

const { minBidLabel, minPaymentLabel } = generalLiterals.modals.bid;

export function useModalBidDataItems(
  radioSelected: WayToPayRadioOptions,
  marketItem: MarketItem,
  minInitialPayment?: bigint
) {
  return useMemo<DataItem[]>(() => {
    const items: DataItem[] = [
      {
        label: "Collection",
        value: marketItem.nft.name,
        type: "string",
      },
      {
        label: "Token ID",
        value: `#${marketItem.nft.tokenId}`,
        type: "string",
      },
      {
        label: "Valuation",
        value: marketItem.nft.valuation,
        type: "currency",
        currency: marketItem.currency,
      },
      {
        label: minBidLabel,
        value: marketItem.minBid,
        type: "currency",
        currency: marketItem.currency,
      },
    ];

    radioSelected === WayToPayRadioOptions.TAKE_LOAN &&
      minInitialPayment &&
      items.push({
        label: minPaymentLabel,
        value: minInitialPayment,
        type: "currency",
        currency: marketItem.currency,
      });

    return items;
  }, [radioSelected, marketItem, minInitialPayment]);
}
