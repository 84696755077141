"use client";
import {
  type FC,
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo,
} from "react";
import { BasicProps } from "../types/BasicProps";
import { useWallet } from "./WalletProvider";
import { useErrorAlert } from "../../logic/hooks";
import { handleErrors } from "../../errors";
import { verisModule } from "../../clients/verisModule";
import currenciesModule from "logic/CurrenciesModule";
import { Address } from "@unlockdfinance/verislabs-web3";

type BalanceContextProps = {
  balances?: { id: string; balance: bigint }[];
  forceBalanceUpdate: () => void;
};

export const BalanceContext = createContext<BalanceContextProps>({
  balances: undefined,
  forceBalanceUpdate: () => { },
});

// type GetCurrencyIds<T extends ICurrency[]> = T[number]["id"];

// type CurrencyIds = GetCurrencyIds<typeof CURRENCIES>;

const CURRENCIES = verisModule.networks
  .map((network) => currenciesModule.getCurrencies(network.CHAIN.id))
  .flat();

const BalanceProvider: FC<BasicProps> = ({ children }) => {
  const { address } = useWallet();
  const [balances, setBalances] = useState<{ balance: bigint; id: string }[]>();
  const [sensor, toggleBalance] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  useErrorAlert(error);

  useEffect(() => {
    if (address) {
      handleGetBalances(address);

      return () => {
        setBalances(undefined);
      };
    }
  }, [address, sensor]);

  const handleGetBalances = async (address: Address) => {
    try {
      const balances = await Promise.all(
        CURRENCIES.map(async (currency) => ({
          id: currency.id,
          balance: await currency.getWalletBalance(address),
        }))
      );

      setBalances(balances);
    } catch (err) {
      setError(handleErrors(err));
    }
  };

  const handleUpdateBalance = () => {
    toggleBalance(!sensor);
  };

  return (
    <BalanceContext.Provider
      value={{
        balances,
        forceBalanceUpdate: handleUpdateBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

const useWalletBalance = () => {
  return useContext(BalanceContext);
};

export { BalanceProvider, useWalletBalance };

export function useTokenBalance(id?: string) {
  const { balances, forceBalanceUpdate } = useContext(BalanceContext);

  const balance = useMemo<bigint | undefined>(() => {
    if (!balances || !id) return undefined;

    const currency = balances.find((balance) => balance.id === id);

    if (!currency) return undefined;

    return currency.balance;
  }, [id, balances]);

  return {
    balance,
    forceBalanceUpdate,
  };
}
